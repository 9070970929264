import {Button, DatePicker, Form, Input, message, Radio, Select} from "antd";
import axiosInstance from "../api";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {filterOption, requiredField} from "../util";
import BackTitle from "../common/BackTitle";


export default function NewVehicle() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [types, setTypes] = useState([]);

    useEffect(() => {
        axiosInstance.get("vehicleCategory")
            .then(({data}) => setTypes(data.map(x => ({label: x.name, value: x.id}))))
    }, []);

    const onFinish = (form) => {
        setLoading(true)
        form.registrationDate = form.registrationDate && form.registrationDate.format("YYYY-MM-DD")
        axiosInstance.post("vehicle", form)
            .then(x => navigate(-1))
            .then(() => message.success("Veicolo inserito con successo"))
            .finally(() => setLoading(false))
    }

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <BackTitle title={"Nuovo veicolo"} padding={0}/>
        <Form layout={"vertical"} onFinish={onFinish}>
            <Form.Item label={"Targa/Matricola"} name="licensePlate" rules={[requiredField]}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Categoria"} name={"category"} rules={[requiredField]}>
                <Select options={types} showSearch filterOption={filterOption}/>
            </Form.Item>
            <Form.Item label={"Produttore"} name={"manufacturer"} rules={[requiredField]}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Modello"} name={"model"} rules={[requiredField]}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Veicolo da cantiere"} name={"buildingSiteUse"} rules={[requiredField]}>
                <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label={"Nr. telaio"} >
                <Input/>
            </Form.Item>
            <Form.Item label={"Etichetta"} name={"label"}>
                <Input placeholder="Automatico"/>
            </Form.Item>
            <Form.Item label={"Data immatricolazione"} name={"registrationDate"} >
                <DatePicker format={"DD/MM/YYYY"}/>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button loading={loading} htmlType={"submit"} type={"primary"}>Salva</Button>
            </Form.Item>
        </Form>
    </div>
}
