import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Space,
} from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import BackTitle from "../common/BackTitle";
import "react-quill/dist/quill.snow.css";
import { requiredField } from "../util";
import TextArea from "antd/lib/input/TextArea";
import axiosInstance from "../api";

export default function NewMaintenance() {
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onFormFinish = (form) => {
        form.date = form.date.format("YYYY-MM-DD");
        form.vehicle = searchParams.get("vehicle");
        form = {
            ...form,
            ...(form.filters ?? []).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
            ...(form.brakes ?? []).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
        };

        axiosInstance
            .post(`/maintenance`, form)
            .then(() => navigate(-1))
            .then(() => message.success("Creato con successo"))
            .finally(() => setLoading(false));
    };
    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <BackTitle title={"Nuova manutenzione"} padding={0} />
            <Divider orientation="left">Dati generali</Divider>
            <Form layout={"vertical"} onFinish={onFormFinish}>
                <Space direction="horizontal" split={<Divider type="vertical" />}>
                    <Form.Item label={"Data"} name={"date"} rules={[requiredField]}>
                        <DatePicker format={"DD/MM/YYYY"} />
                    </Form.Item>
                    <Form.Item label={"Odometro"} name={"odometer"}>
                        <InputNumber
                            placeholder="Quantità"
                            style={{ width: 150 }}
                            min={0}
                            suffix="KM"
                        />
                    </Form.Item>
                    <Form.Item label={"Ore motore"} name={"hours"}>
                        <InputNumber
                            placeholder="Quantità"
                            style={{ width: 150 }}
                            min={0}
                            suffix="Ore"
                        />
                    </Form.Item>
                </Space>

                <Divider orientation="left">Olio motore</Divider>
                <div>
                    <Space>
                        <Form.Item name={"engineOilType"}>
                            <Input placeholder="Tipo" />
                        </Form.Item>
                        <Form.Item name={"engineOilQuantity"}>
                            <InputNumber
                                min={0}
                                placeholder="Quantità"
                                suffix="Litri"
                                style={{ width: 150 }}
                            />
                        </Form.Item>
                    </Space>
                </div>

                <Divider orientation="left">Liquidi</Divider>
                <div>
                    <Space>
                        <Form.Item label="Freni" name="brakeLiquidQuantity">
                            <InputNumber
                                min={0}
                                placeholder="Quantità"
                                suffix="Litri"
                                style={{ width: 150 }}
                            />
                        </Form.Item>

                        <Form.Item label="Antigelo" name="antifreezeLiquidQuantity">
                            <InputNumber
                                min={0}
                                placeholder="Quantità"
                                suffix="Litri"
                                style={{ width: 150 }}
                            />
                        </Form.Item>
                    </Space>
                </div>

                <Divider orientation="left">Filtri</Divider>
                <Form.Item name={"filters"}>
                    <Checkbox.Group>
                        <Checkbox value="oilFilter">Olio</Checkbox>
                        <Checkbox value="airFilter">Aria</Checkbox>
                        <Checkbox value="dieselFilter">Gasolio</Checkbox>
                        <Checkbox value="pollenFilter">Antipolline</Checkbox>
                    </Checkbox.Group>
                </Form.Item>

                <Divider orientation="left">Freni</Divider>
                <Form.Item name={"brakes"}>
                    <Checkbox.Group>
                        <Checkbox value="frontLeftBrake">Ant. SX</Checkbox>
                        <Checkbox value="frontRightBrake">Ant. DX</Checkbox>
                        <Checkbox value="rearLeftBrake">Post. SX</Checkbox>
                        <Checkbox value="rearRightBrake">Post. DX</Checkbox>
                    </Checkbox.Group>
                </Form.Item>

                <Divider orientation="left">Altri dati</Divider>
                <Form.Item label={"Note"} name={"notes"}>
                    <TextArea rows={5} />
                </Form.Item>

                <Form.Item style={{ textAlign: "end" }}>
                    <Button loading={loading} type={"primary"} htmlType={"submit"}>
                        Salva
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
