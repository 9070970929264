import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import CheckLogin from "./CheckLogin";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import itIT from "antd/locale/it_IT";
import EditVehicle from "./vehicle/EditVehicle";
import _ from 'dayjs/locale/it'
import NewVehicle from "./vehicle/NewVehicle";
import Vehicles from "./vehicle/Vehicles";
import VehicleDetails from "./vehicle/VehicleDetails"
import NewDocument from "./document/NewDocument";
import NewMaintenance from "./maintenance/NewMaintenance";
import EditMaintenance from "./maintenance/EditMaintenance";
import EditDueDates from "./vehicle/EditDueDates";
import MaintenanceReminders from "./maintenancereminder/MaintenanceReminders";
import MaintenancePlans from "./maintenanceplan/MaintenancePlans";
import NewMaintenancePlan from "./maintenanceplan/NewMaintenancePlan";
import EditMaintenancePlan from "./maintenanceplan/EditMaintenancePlan";
import FetchUserInfo from "./common/FetchUserInfo";
import App from "./App";
var utc = require("dayjs/plugin/utc");

dayjs.extend(utc);
const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "vehicles",
                element: <Vehicles />,
            },
            {
                path: "vehicles/:id",
                element: <VehicleDetails />,
            },
            {
                path: "vehicles/:id/edit",
                element: <EditVehicle />,
            },
            {
                path: "vehicles/:id/editDueDates",
                element: <EditDueDates/>
            },
            {
                path: `vehicles/new`,
                element: <NewVehicle/>,
            },
            {
                path: "maintenances/new",
                element: <NewMaintenance />,
            },
            {
                path: `maintenances/:id/edit`,
                element: <EditMaintenance />,
            },
            {
                path: "documents/new",
                element: <NewDocument/>
            },
            {
                path: 'maintenanceReminders',
                element: <MaintenanceReminders/>
            },
            {
                path: 'maintenancePlans',
                element: <MaintenancePlans/>
            },
            {
                path: 'maintenancePlans/new',
                element: <NewMaintenancePlan/>
            },
            {
                path: 'maintenancePlans/:id/edit',
                element: <EditMaintenancePlan/>
            }
        ],
    },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));

dayjs.locale("it");

root.render(
    <ConfigProvider locale={itIT}>
        <CheckLogin>
            <FetchUserInfo>
                <RouterProvider router={router}/>
            </FetchUserInfo>
        </CheckLogin>
    </ConfigProvider>,
);
