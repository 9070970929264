import {
    Button,
    Col,
    Descriptions,
    Divider,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
} from "antd";
import Search from "antd/lib/input/Search";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    CheckCircleTwoTone,
    CheckOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import axiosInstance from "../api";
import { downloadFile, formatDateTime, textFromHtml } from "../util";
import Highlighter from "react-highlight-words";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import InnerHTML from "../common/InnerHTML";
import PermissionRequired from "../common/PermissionRequired";

const checkIcon = <CheckOutlined twoToneColor="#52c41a" />;
const attributes = [
    {
        key: "id",
        label: <b>Manutenzione numero</b>,
        render: (_, record) => <Link to={`/maintenances/${record.id}/edit`}>{record.id}</Link>,
    },
    {
        key: "createdBy",
        label: "Creata da",
        render: (value) => value.fullName,
    },
    { key: "GENERAL_DATA_SECTION", label: <b>DATI GENERALI</b>, fullSpan: true },
    { key: "odometer", label: "Odometro (KM)" },
    { key: "hours", label: "Ore motore (h)" },
    { key: "ENGINE_OIL_SECTION", label: <b>OLIO MOTORE</b>, fullSpan: true },
    { key: "engineOilType", label: "Tipo olio motore" },
    { key: "engineOilQuantity", label: "Olio motore (litri)" },
    { key: "LIQUID_SECTION", label: <b>LIQUIDI</b>, fullSpan: true },
    { key: "brakeLiquidQuantity", label: "Liquido freni (litri)" },
    { key: "antifreezeLiquidQuantity", label: "Liquido antigelo (litri)" },
    { key: "FILTER_SECTION", label: <b>FILTRI</b>, fullSpan: true },
    { key: "oilFilter", label: "Filtro olio", render: (v) => (v ? checkIcon : "") },
    { key: "airFilter", label: "Filtro aria", render: (v) => (v ? checkIcon : "") },
    { key: "dieselFilter", label: "Filtro gasolio", render: (v) => (v ? checkIcon : "") },
    { key: "pollenFilter", label: "Filtro antipolline", render: (v) => (v ? checkIcon : "") },
    { key: "BRAKE_SECTION", label: <b>FRENI</b>, fullSpan: true },
    { key: "frontLeftBrake", label: "Freno ant. sx.", render: (v) => (v ? checkIcon : "") },
    { key: "frontRightBrake", label: "Freno ant. dx.", render: (v) => (v ? checkIcon : "") },
    { key: "rearLeftBrake", label: "Freno post. sx.", render: (v) => (v ? checkIcon : "") },
    { key: "rearRightBrake", label: "Freno post. dx.", render: (v) => (v ? checkIcon : "") },
    { key: "OTHER_SECTION", label: <b>ALTRO</b>, fullSpan: true },
    { key: "notes", label: "Note" },
];

const attrToTitle = (attr) => attributes.filter((x) => x[0].localeCompare(attr)?.[1] ?? "");

export default function Maintenances() {
    const { id } = useParams();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const navigate = useNavigate();
    const [sp, setSP] = useSearchParams();
    const query = sp.get("q") ?? "";

    const refresh = () => {
        setLoading(true);
        axiosInstance
            .get(`maintenance`, { params: { vehicle: id } })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        setExpandedRowKeys([]);
    }, [sp]);

    const columns = [
        {
            key: "title",
            dataIndex: "label",
            align: "left",
            width: 150,
            onCell: (record) => (record.fullSpan ? { colSpan: data.length + 1 } : {}),
        },
        {
            key: "date",
            title: "Data",
            align: "center",
            children: data
                .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))
                .map((x, index) => ({
                    key: x.date,
                    dataIndex: "key",
                    align: "center",
                    width: 70,
                    title: dayjs(x.date).format("DD MMM YYYY"),
                    onCell: (record) => (record.fullSpan ? { colSpan: 0 } : {}),
                    render: (key, record) =>
                        record.render
                            ? record.render(
                                  data.filter((y) => y.id === x.id)[0][key],
                                  data.filter((y) => y.id === x.id)[0],
                              )
                            : data.filter((y) => y.id === x.id)[0][key],
                })),
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <div>
                <Space size={0}>
                    <PermissionRequired name="maintenance.create">
                        <Button
                            type={"primary"}
                            icon={<PlusOutlined />}
                            onClick={() => navigate(`/maintenances/new?vehicle=${id}`)}
                        >
                            Nuova manutenzione
                        </Button>
                    </PermissionRequired>
                </Space>
                <Divider style={{ margin: 0, marginTop: 5 }} />
            </div>
            {data.length === 0 ? <>Nessuna manutenzione inserita</> : <Table
                scroll={{ x: 270, y: 700 }}
                tableLayout={"fixed"}
                size={"small"}
                columns={columns}
                loading={loading}
                bordered
                pagination={false}
                rowClassName={(record) => (record.fullSpan ? "table-row-dark" : "table-row-light")}
                dataSource={attributes}
            />}
        </div>
    );
}
