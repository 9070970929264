import { Table } from "antd";
import axiosInstance from "../api";
import { useEffect, useState } from "react";
import { ClockCircleOutlined, WarningOutlined } from "@ant-design/icons";
import BackTitle from "../common/BackTitle";
import { Link } from "react-router-dom";

const getUnit = (x) =>
    ({
        DISTANCE_BASED: "km",
        HOUR_BASED: "ore",
        DATE_BASED: "giorni",
    })[x];

export default function MaintenanceReminders() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const loadData = () => {
        setLoading(true);
        axiosInstance
            .get(`maintenanceReminder`)
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        loadData();
    }, []);

    const columns = [
        {
            key: "vehicle",
            dataIndex: "vehicle",
            width: 70,
            title: "Veicolo",
            ellipsis: true,
            render: (x) => <Link to={`/vehicles/${x?.id}?t=3`}>{x?.label} ({x?.licensePlate})</Link>,
        },
        {
            key: "maintenancePlan",
            dataIndex: "maintenancePlan",
            width: 100,
            ellipsis: true,
            title: "Piano di manutenzione",
            render: (x) => `${x.name} (ogni ${x.dueQuantity} ${getUnit(x.type)})`,
        },
        {
            key: "message",
            width: 100,
            title: "Messaggio",
            ellipsis: true,
            render: (record) => (
                <span style={{ fontWeight: "bold", color: record.expired ? "red" : "orange" }}>
                    {record.expired ? (
                        <>
                            <WarningOutlined /> SCADUTO DA{" "}
                        </>
                    ) : (
                        <>
                            <ClockCircleOutlined /> SCADE TRA{" "}
                        </>
                    )}
                    {record.deltaQuantity.toLocaleString("it-IT") + " "}
                    {getUnit(record.maintenancePlan.type)}
                </span>
            ),
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <BackTitle backEnabled={false} title="Promemoria manutenzioni" padding={0}/>
            <Table
                loading={loading}
                scroll={{ x: 1140 }}
                tableLayout={"fixed"}
                size={"small"}
                columns={columns}
                dataSource={data.map((x) => ({
                    ...x,
                    key: x.vehicle.id + "_" + x.maintenancePlan.id,
                }))}
            />
        </div>
    );
}
